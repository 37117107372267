/* You can add global styles to this file, and also import other style files */

/* @tailwind base;  
@tailwind components;  
@tailwind utilities; */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.ngx-pagination .current {
    background: #00cc67 !important;
    }
    .tg-pagination ul li
    {
       width:auto !important; 
       height: auto !important;
    }